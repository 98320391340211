<template>
  <div class="resources-bookdetail-bg">
    <div class="resources-bookdetail">
      <div class="reader-wrapper">
        <div id="read" class="read"></div>
        <div class="mask">
          <div class="left" @click="prevPage">上一章</div>
          <div class="center"></div>
          <div class="right" @click="nextPage">下一章</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Epub from "epubjs";
export default {
  data() {
    return {
      book_url: "",
      book: "",
    };
  },
  created() {},
  mounted() {
    this.book_url = this.$route.query.book_url;
    this.book_url = "https://image.bookgo.com.cn/%20bgOfficial/1648607726_n.pdf.epub"
    this.showEpub();
  },
  methods: {
    // 电子书解析和渲染
    showEpub() {
      // 生成Ebook
      console.log(this.book_url)
      this.book = new Epub(this.book_url);
      console.log(this.book)
      // 生成rendition，通过Book.readerTo方法生成
      this.rendition = this.book.renderTo("read", {
        width: "1200",
        height: "1000"
      });
      console.log(this.rendition);
      console.log(this.book)

      // 通过Rendition.display()渲染电子书
      this.rendition.display();
    },
    prevPage() {
      if (this.rendition) {
        this.rendition.prev();
      }
    },
    nextPage() {
      if (this.rendition) {
        this.rendition.next();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/resources_bookdetail.scss";
</style>